import styled from "styled-components";
const Loader = ({ type = "big", styles = {} }) => {
    return type === "button-loader" ? (
        <ButtonLoader $styles={styles} />
    ) : (
        <main className="preloader">
            <svg
                className="pl"
                viewBox="0 0 128 128"
                width="128px"
                height="128px"
                xmlns="http://www.w3.org/2000/svg">
                <g fill="none" strokeLinecap="round" strokeWidth="16" transform="rotate(-90,64,64)">
                    <circle className="pl__ring" r="56" cx="64" cy="64" stroke="#ddd" />
                    <circle
                        className="pl__worm pl__worm--moving"
                        r="56"
                        cx="64"
                        cy="64"
                        stroke="currentColor"
                        strokeDasharray="22 307.86 22"
                        data-worm
                    />
                </g>
                <g data-particles></g>
            </svg>
        </main>
    );
};

export default Loader;

const ButtonLoader = styled.div.attrs((props) => ({}))`
    pointer-events: none;
    width: ${(props) => (props.$styles?.width ? props.$styles?.width : "32px")};
    height: ${(props) => (props.$styles?.height ? props.$styles?.height : "32px")};
    border-radius: 50%;
    border: 3px solid #ffffff7a;
    border-top-color: #fff;
    animation: ani1 1s ease infinite;
    @keyframes ani1 {
        0% {
            transform: rotate(0turn);
        }
        100% {
            transform: rotate(1turn);
        }
    }
`;

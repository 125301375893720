import toastr from "toastr";

export const handleToastr = (msg, type = "success", timeout = 1500) => {
    toastr.options = {
        positionClass: "toast-top-right",
        hideDuration: 300,
        timeOut: timeout,
        closeButton: true,
    };
    toastr.clear();
    setTimeout(() => {
        if (type == "success") {
            return toastr.success(msg);
        } else if (type == "warning") {
            return toastr.warning(msg);
        } else if (type == "error") {
            return toastr.error(msg);
        }
    }, 300);
};

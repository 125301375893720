import { getIcon } from "@litonarefin/utils/icons/index";
import styled from "styled-components";
import { createPortal } from "react-dom";
import StyleIcon from "@litonarefin/components/StyledIcon";

const Modal = ({
    children,
    onCloseModal,
    width = "350px",
    height = "180px",
    closeOutside = false,
}) => {
    let el = document.createElement("div");
    el.id = "jlt-modal";
    document.body.appendChild(el);

    return createPortal(
        <Wrapper>
            <ModalContent $modalWidth={width} $modalHeight={height}>
                <span
                    onClick={() => onCloseModal()}
                    className={`jlt-absolute jlt-cursor-pointer jlt-z-50 ${
                        closeOutside
                            ? "-jlt-top-8 jlt-right-2"
                            : " jlt-top-4 jlt-right-[18px]"
                    }`}>
                    <StyleIcon
                        {...(closeOutside ? { color: "#fff" } : {})}
                        styles={{ width: "16px", height: "16px" }}>
                        {getIcon("close")}
                    </StyleIcon>
                </span>
                {children}
            </ModalContent>
        </Wrapper>,
        document.getElementById("jlt-modal")
    );
};

export default Modal;

const Wrapper = styled.div.attrs((props) => ({
    className:
        "jlt-fixed jlt-top-0 jlt-left-0 jlt-w-full jlt-h-full jlt-bg-[#16213e74] jlt-z-[99999999] jlt-flex jlt-justify-center jlt-items-center",
}))`
    animation: anim;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
`;

// min-width: ${(props) => props.$modalWidth};
// min-height: ${(props) => props.$modalHeight};

const ModalContent = styled.div.attrs((props) => ({
    className: "jlt-relative jlt-z-[99999]",
}))`
    width: ${(props) => props.$modalWidth};
    max-width: ${(props) => props.$modalWidth};
    height: ${(props) => props.$modalHeight};
    max-height: ${(props) => props.$modalHeight};
`;

// import React from "react";
// import { createPortal } from "react-dom";

// const modalStyle = {
//     position: "fixed",
//     inset: "0px",
//     background: "rgba(35, 40, 45, 0.62)",
//     height: "100%",
//     width: "100%",
//     bottom: 0,
//     left: 0,
//     display: "flex",
//     alignItems: "center",
//     zIndex: 999999999,
// };

// const OutreachModal = ({ children, onClose, title = "" }) => {
//     let el = document.createElement("div");
//     el.id = "jlt-outreach-modal";
//     document.body.appendChild(el);

//     return createPortal(
//         <div className="jlt-outreach-modal-content" style={modalStyle}>
//             <div className="jlt-outreach-modal--content">
//                 <div className="jlt-outreach-modal--header">
//                     {title ? <h3>{title}</h3> : <div></div>}
//                     <button className="jlt-outreach-modal-close-btn" onClick={() => onClose()}>
//                         X
//                     </button>
//                 </div>
//                 <div className="jlt-outreach-modal--body">{children}</div>
//                 {/* <div className="jlt-outreach-modal--footer">Footer</div> */}
//             </div>
//         </div>,
//         document.getElementById("jlt-outreach-modal")
//     );
// };

// export default OutreachModal;

"use client";

import { getSourceSite } from "@litonarefin/utils/getSourceSite";
import { isEmpty } from "lodash";
import { useEffect, useState, createContext, useContext } from "react";

//auth context
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(null);
    const [cartCount, setCartCount] = useState(0);

    const siteSource = getSourceSite();

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        const cartData = localStorage.getItem(`jlt-cart`);

        const data = isJson(cartData) ? JSON.parse(cartData) : null;
        if (!isEmpty(data)) {
            setCart(data);
        } else {
            setCart(null);
        }
    }, []);

    useEffect(() => {
        if (!!cart) {
            localStorage.setItem(`jlt-cart`, JSON.stringify(cart));
            setCartCount(cart?.length || 0);
        }
    }, [cart]);

    return (
        <CartContext.Provider
            value={{
                cart,
                setCart,
                cartCount,
                setCartCount,
            }}>
            {children}
        </CartContext.Provider>
    );
};

export const useGlobalCartContext = () => {
    return useContext(CartContext);
};
